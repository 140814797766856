exports.components = {
  "component---src-features-blog-blog-single-tsx": () => import("./../../../src/features/blog/BlogSingle.tsx" /* webpackChunkName: "component---src-features-blog-blog-single-tsx" */),
  "component---src-features-blog-blog-tsx": () => import("./../../../src/features/blog/Blog.tsx" /* webpackChunkName: "component---src-features-blog-blog-tsx" */),
  "component---src-features-knowledgebase-knowledgebase-article-tsx": () => import("./../../../src/features/knowledgebase/KnowledgebaseArticle.tsx" /* webpackChunkName: "component---src-features-knowledgebase-knowledgebase-article-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-affiliates-tsx": () => import("./../../../src/pages/affiliates.tsx" /* webpackChunkName: "component---src-pages-affiliates-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-earnings-tsx": () => import("./../../../src/pages/earnings.tsx" /* webpackChunkName: "component---src-pages-earnings-tsx" */),
  "component---src-pages-embed-tsx": () => import("./../../../src/pages/embed.tsx" /* webpackChunkName: "component---src-pages-embed-tsx" */),
  "component---src-pages-enterprise-tsx": () => import("./../../../src/pages/enterprise.tsx" /* webpackChunkName: "component---src-pages-enterprise-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-knowledgebase-tsx": () => import("./../../../src/pages/knowledgebase.tsx" /* webpackChunkName: "component---src-pages-knowledgebase-tsx" */),
  "component---src-pages-margin-calculator-tsx": () => import("./../../../src/pages/margin-calculator.tsx" /* webpackChunkName: "component---src-pages-margin-calculator-tsx" */),
  "component---src-pages-on-the-internet-tsx": () => import("./../../../src/pages/on-the-internet.tsx" /* webpackChunkName: "component---src-pages-on-the-internet-tsx" */),
  "component---src-pages-options-strategies-tsx": () => import("./../../../src/pages/options-strategies.tsx" /* webpackChunkName: "component---src-pages-options-strategies-tsx" */),
  "component---src-pages-position-management-tsx": () => import("./../../../src/pages/position-management.tsx" /* webpackChunkName: "component---src-pages-position-management-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-roadmap-tsx": () => import("./../../../src/pages/roadmap.tsx" /* webpackChunkName: "component---src-pages-roadmap-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-trade-analytics-tsx": () => import("./../../../src/pages/trade-analytics.tsx" /* webpackChunkName: "component---src-pages-trade-analytics-tsx" */),
  "component---src-pages-trading-management-tsx": () => import("./../../../src/pages/trading-management.tsx" /* webpackChunkName: "component---src-pages-trading-management-tsx" */),
  "component---src-pages-tradingjournal-tsx": () => import("./../../../src/pages/tradingjournal.tsx" /* webpackChunkName: "component---src-pages-tradingjournal-tsx" */)
}

