import * as React from "react";
import { Box, ChakraProvider } from "@chakra-ui/react";
import type { WrapRootElementBrowserArgs } from "gatsby";
import { customTheme } from "./theme";
import "./base.css";
import { Helmet } from "react-helmet";

export const WrapRootElement = ({
  element,
}: Pick<WrapRootElementBrowserArgs, "element">) => (
  // Or ChakraBaseProvider if you only want to compile the default Chakra theme tokens
  <ChakraProvider theme={customTheme}>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "WealthBee",
            "applicationCategory": "FinanceApplication",
            "operatingSystem": "Web",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            },
            "description": "Advanced trading platform for position management, trade analytics, and trading management",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "ratingCount": "156"
            },
            "featureList": [
              "Position Management",
              "Trade Analytics",
              "Trading Management",
              "Performance Metrics",
              "Strategy Analysis",
              "Risk Management"
            ]
          }
        `}
      </script>
    </Helmet>
    <Box color="white">{element}</Box>
  </ChakraProvider>
);
